import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Directive, Input, ElementRef, OnInit, AfterViewInit, PLATFORM_ID, OnDestroy, inject, Renderer2 } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appParallax]',
})
export class ParallaxDirective implements AfterViewInit, OnDestroy {
  @Input() parallaxRatio = 1;
  @Input() loadParallax = false;

  private router = inject(Router);
  private renderer = inject(Renderer2);
  private platformId = inject(PLATFORM_ID);
  private document: any = inject(DOCUMENT);

  initialTop = 0;
  private _unsubscribe$ = new Subject<void>();

  constructor(private eleRef: ElementRef) {
    this.initialTop = this.eleRef.nativeElement.getBoundingClientRect().top;
  }
  // ngOnInit(): void {
  //   throw new Error('Method not implemented.');
  // }

  ngAfterViewInit() {
    this.router.events.pipe(takeUntil(this._unsubscribe$)).subscribe(event => {
      if (isPlatformBrowser(this.platformId) && event instanceof NavigationStart) {
        //     this.eleRef.nativeElement.style.opacity = `0`;
      }

      if (isPlatformBrowser(this.platformId) && event instanceof NavigationEnd) {
        //         this.eleRef.nativeElement.style.opacity = `1`;
        setTimeout(() => {
          this.eleRef.nativeElement.style.transform = `translate3d(0, 0px, 0)`;
        }, 300);
      }
    });

    if (isPlatformBrowser(this.platformId)) {
      let topPos = 0;
      fromEvent(window, 'scroll').subscribe(a => {
        if (this.loadParallax) {
          // show scrollbar after windowinnerheight (window.innerHeight)
          topPos = this.initialTop - window.scrollY * this.parallaxRatio;

          // console.log(topPos);
          this.eleRef.nativeElement.style.transform = `translate3d(0, ${topPos}px, 0)`;
        }
      });

      this.eleRef.nativeElement.style.transform = `translate3d(0, ${topPos}px, 0)`;
    }
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  // @HostListener('window:scroll', ['$event'])
  // onWindowScroll(event) {
  //   if (this.loadParallax) {
  //     // this.eleRef.nativeElement.style.top = this.initialTop - window.scrollY * this.parallaxRatio + 'px';
  //     this.eleRef.nativeElement.style.transform = `translate3d(0, ${this.initialTop - window.scrollY * this.parallaxRatio}px, 0)`;
  //   }
  // }
}
