<footer class="footer section-inset-y">
  <div class="container fs-body-xs">
    <div class="row">
      <div class="col-md-5 footer__contact">
        <h3 class="h2 footer__title">Contact</h3>
        <div class="row">
          <div class="col-md-6" [innerHTML]="'footer-contact-nl' | fromDictionary"></div>
          <div class="col-md-6" [innerHTML]="'footer-contact-fr' | fromDictionary"></div>
        </div>
      </div>

      <nav class="col-md-5">
        <h3 class="h2 footer__title">
          &nbsp;
          <span class="sr-only">footer navigatie</span>
        </h3>
        <ul class="footer__nav-list">
          @for (page of menu; track page) {
            <li class="topbar__nav__list__item mb-1">
              <a class="footer-link" [routerLink]="page.url" [routerLinkActive]="'is--current'">
                {{ page.title }}
              </a>
            </li>
          }
          <li class="footer__nav-list__item">
            <button (click)="openCookie()" class="footer-link">{{ 'cookie-settings' | fromDictionary }}</button>
          </li>
        </ul>
      </nav>

      <div class="col-md-2"></div>
    </div>
    <nav class="footer__socials">
      <ul class="footer__socials__list d-flex gap-2">
        @for (social of socials; track social) {
          <li class="footer__socials__list__item">
            <a [href]="social.url" target="_blank" rel="noopener" class="button button-icon button--dark">
              @if (social.icon) {
                <fstr-icon [anchor]="social.icon"></fstr-icon>
              }
              <span [class.sr-only]="social.icon">{{ social.title }}</span>
            </a>
          </li>
        }
      </ul>
    </nav>
  </div>
  <div class="footer__end section-inset-t text-center d-flex flex-column text-light">
    <span class="footer__logo"><fstr-icon anchor="logo-flat"></fstr-icon></span>
    <span class="h4 mb-4">{{ 'meta-subtitle-text' | fromDictionary }}</span>
    <strong class="fs-body-xxs">{{ year }} &copy; {{ 'meta-titel-suffix' | fromDictionary }}</strong>
  </div>

  <app-json-ld [json]="schema"></app-json-ld>
</footer>
