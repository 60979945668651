import { Component, Input, ChangeDetectorRef, Output, EventEmitter, PLATFORM_ID, inject, effect } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import * as fromMenu from '@teamfoster/sdk/menu-ngrx';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
})
export class TopbarComponent {
  private router = inject(Router);

  @Input() loading: boolean = false;
  @Input() menuActive: boolean = false;
  @Input() logoClass: string = '';
  @Input() menu: fromMenu.MenuItem[] = [];

  @Output() toggleOverlay = new EventEmitter<void>();
  @Output() closeOverlay = new EventEmitter<void>();
  @Output() openOverlay = new EventEmitter<void>();

  private routerEvents$ = toSignal(this.router.events.pipe(filter(e => e instanceof NavigationStart)));

  routerEffect$ = effect(
    () => {
      if (this.routerEvents$() instanceof NavigationStart && this.menuActive) {
        this.closeOverlay.emit();
      }
    },
    { allowSignalWrites: true }
  );

  get hasHome(): boolean {
    return this.menu.some(item => item.url === '/');
  }

  get homeNav() {
    return this.menu.find(item => item.url === '/');
  }
}
