<div class="topbar py-md-4">
  <div class="container container-size-xxl d-flex align-items-center gap-3 px-4">
    <h1 class="topbar__logo mb-0">
      <a [routerLink]="'/'">
        <img src="/assets/gfx/logo.svg" class="topbar__logo__img" />
        <span class="sr-only">Thorizon</span>
      </a>
    </h1>
    <nav class="topbar__nav">
      <ul class="topbar__nav__list d-flex gap-xl-5 gap-3 pe-md-3">
        @if (homeNav) {
          <li class="topbar__nav__list__item">
            <a
              (click)="toggleOverlay.emit()"
              class="button button-link button--light"
              [routerLink]="homeNav.routerLink"
              [routerLinkActive]="'is--current'"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              {{ homeNav.title }}
            </a>
          </li>
        }
        @for (page of menu; track page) {
          @if (page.url !== '/') {
            <li class="topbar__nav__list__item">
              <a
                (click)="toggleOverlay.emit()"
                class="button button-link button--light"
                [routerLink]="page.url"
                [routerLinkActive]="'is--current'"
              >
                {{ page.title }}
              </a>
            </li>
          }
        }
      </ul>
    </nav>
    <button class="hamburger-toggle d-inline-block d-md-none" (click)="toggleOverlay.emit()">
      <span [class.hamburger-toggle__lines--active]="menuActive" class="hamburger-toggle__lines"></span>
      <span class="sr-only">Toggle navigation</span>
    </button>
  </div>
</div>
